import { CommerceProperty } from '@msdyn365-commerce/retail-proxy';
import { ObjectExtensions } from '@msdyn365-commerce-modules/retail-actions';

export class ExtensionPropertiesConverter<Type> {
    public ConvertToArrayOfType(extensionProperties: CommerceProperty[], key: string): Type[] {
        const arrayOfType: Type[] = [];

        extensionProperties.map(extensionProperty => {
            if (!ObjectExtensions.isNullOrUndefined(extensionProperty.Key) && extensionProperty.Key.includes(key)) {
                if (
                    !ObjectExtensions.isNullOrUndefined(extensionProperty.Value) &&
                    !ObjectExtensions.isNullOrUndefined(extensionProperty.Value.StringValue)
                ) {
                    const item: Type = JSON.parse(extensionProperty.Value.StringValue);
                    arrayOfType.push(item);
                }
            }
        });
        return arrayOfType;
    }
}
