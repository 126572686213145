//import { ReactElement } from 'enzyme/node_modules/@types/react';
import * as React from 'react';
import _ from 'lodash';
import { CartLine, SalesLine } from '@msdyn365-commerce/retail-proxy';
import { ObjectExtensions } from '@msdyn365-commerce-modules/retail-actions';

import { ExtensionPropertiesConverter } from '../../../Common/ExtensionProperties/ExtensionPropertiesConverter';
import {
    IngredientKeyPrefix,
    ProductModifierGroupKeyPrefix,
    DealLineKeyPrefix,
    CommentKeyPrefix
} from '../../../../Domain/Common/Constants/ExtensionPropertyKeys';
import {
    ILshIngredient,
    ILshProductModifier,
    ILshProductModifierGroup
} from '../../../../../restaurants.commerce/Platform/RetailProxy/DataServiceEntities.g';
import { DealLine } from '../../../Products/DataModel/Deals/DealLines/DealLine';

export class CartLines {
    public static GetModifications(cartLine: CartLine | SalesLine): React.ReactNode | undefined {
        return new CartLines().CreateReactElement(cartLine);
    }

    private GetModifiedProductModifiers(productModifierGroups: ILshProductModifierGroup[]): ILshProductModifier[] {
        const modifiedProductModifiers: ILshProductModifier[] = [];
        for (const productModifierGroup of productModifierGroups) {
            const modifiers = productModifierGroup.Modifiers;
            if (!modifiers) {
                continue;
            }
            modifiedProductModifiers.push(...modifiers.filter(productModifier => productModifier.Quantity > 0));
        }
        return modifiedProductModifiers;
    }

    private GetModifiedIngredients(ingredients: ILshIngredient[]): ILshIngredient[] {
        return ingredients.filter(ingredient => ingredient.Quantity !== ingredient.OriginalQuantity);
    }

    private createListFromObjectArrayAndPath(
        objectArray: { [key: string]: any }[],
        path: string,
        options: {
            quantityPath?: string;
            originalQuantityPath?: string;
        } = {}
    ): string[] {
        const listString: string[] = [];
        const quantityPath = options.quantityPath ?? '';
        const originalQuantityPath = options.originalQuantityPath ?? '';

        for (let i = 0; i < objectArray.length; i++) {
            const objectFromArray = objectArray[i];
            const objectPropertyString: string = _.get(objectFromArray, path, '');
            if (objectPropertyString === '') {
                continue;
            }
            const quantity: number | null = _.get(objectFromArray, quantityPath, null);
            const originalQuantity: number = _.get(objectFromArray, originalQuantityPath, 0);
            if (quantity !== null && quantity !== originalQuantity) {
                const quantityDifferenceAsString =
                    quantity - originalQuantity < 0 ? `${quantity - originalQuantity}` : `+${quantity - originalQuantity}`;

                const quantityString = quantityDifferenceAsString !== '' ? ` (${quantityDifferenceAsString})` : '';
                listString.push(objectPropertyString + quantityString);
            }
        }
        return listString;
    }

    private GenerateDealModifiedItemsJSX(dealLines: DealLine[]): JSX.Element | null {
        if (dealLines.length === 0) {
            return null;
        }
        return (
            <>
                {dealLines.map((dealLine, index) => {
                    // get all the modified and unmodified stuff, to filter later
                    let allIngredients: ILshIngredient[] = [];
                    let allProductModifiers: ILshProductModifierGroup[] = [];

                    // if we swapped with a DealLineModifier, then we get those ingredients
                    if (dealLine.DealModifierToSwapWith) {
                        allIngredients = dealLine.DealModifierToSwapWith.Ingredients ?? [];
                        allProductModifiers = dealLine.DealModifierToSwapWith.ProductModifierGroups ?? [];
                    } else {
                        allIngredients = dealLine.Ingredients ?? [];
                        allProductModifiers = dealLine.ProductModifierGroups ?? [];
                    }
                    const modifiedIngredients = this.GetModifiedIngredients(allIngredients);
                    const modifiedProductModifiers = this.GetModifiedProductModifiers(allProductModifiers);
                    const modifiedComments: string[] = dealLine.Comments?.CommentStrings ?? [];

                    const strings: string[] = [];
                    strings.push(
                        ...this.createListFromObjectArrayAndPath(modifiedIngredients, 'Name', {
                            quantityPath: 'Quantity',
                            originalQuantityPath: 'OriginalQuantity'
                        })
                    );
                    strings.push(
                        ...this.createListFromObjectArrayAndPath(modifiedProductModifiers, 'Name', {
                            quantityPath: 'Quantity',
                            originalQuantityPath: 'OriginalQuantity'
                        })
                    );
                    strings.push(...modifiedComments);

                    const dealLineName = dealLine.DealModifierToSwapWith?.ItemName ?? dealLine.Name;

                    const joinedStrings = strings.join(', ');
                    const colonIfModifiedStuff = joinedStrings === '' ? null : ': ';
                    return (
                        <p key={index}>
                            {dealLineName}
                            {colonIfModifiedStuff}
                            <span className='cartlines_text-muted'>{joinedStrings}</span>
                        </p>
                    );
                })}
            </>
        );
    }

    private ExtractFromCartLine<T>(cartLine: CartLine | SalesLine, keyPrefix: string) {
        if (ObjectExtensions.isNullOrUndefined(cartLine.ExtensionProperties)) {
            return [];
        }

        const arrayOfTypeT: T[] = new ExtensionPropertiesConverter<T>().ConvertToArrayOfType(cartLine.ExtensionProperties, keyPrefix);

        return arrayOfTypeT;
    }

    private GenerateRecipeModifiedItemsJSX(
        modifiedIngredients: ILshIngredient[],
        modifiedProductModifiers: ILshProductModifier[],
        modifiedComments: string[]
    ): JSX.Element | null {
        const ingredientsList = this.createListFromObjectArrayAndPath(modifiedIngredients, 'Name', {
            quantityPath: 'Quantity',
            originalQuantityPath: 'OriginalQuantity'
        });
        const productModifiersList = this.createListFromObjectArrayAndPath(modifiedProductModifiers, 'Name', {
            quantityPath: 'Quantity',
            originalQuantityPath: 'OriginalQuantity'
        });
        const allModifications = [...ingredientsList, ...productModifiersList, ...modifiedComments];

        if (allModifications.length === 0) {
            return null;
        }

        return (
            <p>
                <span className='cartlines_text-muted'>{allModifications.join(', ')}</span>
            </p>
        );
    }

    private CreateReactElement(cartLine: CartLine | SalesLine): React.ReactElement {
        // if cartLine is a recipe, nothing will be generated from these functions
        const dealLinesFromCartLine = this.ExtractFromCartLine<DealLine>(cartLine, DealLineKeyPrefix);
        const dealModifiedItemsJSX = this.GenerateDealModifiedItemsJSX(dealLinesFromCartLine);

        // if cartLine is recipe, get ingredients, productmodifiers, comments
        // flow applies functions from left to right, each next function receives the return args of the previous
        const modifiedIngredients = _.flow(this.ExtractFromCartLine, this.GetModifiedIngredients)(cartLine, IngredientKeyPrefix);
        const modifiedProductModifiers = _.flow(this.ExtractFromCartLine, this.GetModifiedProductModifiers)(
            cartLine,
            ProductModifierGroupKeyPrefix
        );
        const selectedPredefinedComments: string[] = _.flow(this.ExtractFromCartLine, _.flatten)(cartLine, CommentKeyPrefix);

        const recipeModifiedItemsJSX = this.GenerateRecipeModifiedItemsJSX(
            modifiedIngredients,
            modifiedProductModifiers,
            selectedPredefinedComments
        );

        const logStuff = false;
        if (logStuff) {
            console.log(modifiedIngredients, modifiedProductModifiers, selectedPredefinedComments);
        }

        const orderDataAsElement = (
            <div>
                {dealModifiedItemsJSX}
                {recipeModifiedItemsJSX}
            </div>
        );

        return orderDataAsElement;
    }
}
